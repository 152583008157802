<template>
    <div class="header-box">
        <div class="main-box">
           <div class="mb-left">
               <div class="loginc-l-box" v-if="loginType === 'zhengfu'">
                   <img style="width: 60px;height: 60px" src="../assets/images/zfLogin.jpg"/>
                   <span class="item-icons" style="background: #f15f5f">政</span>
               </div>
                <div class="loginc-l-box" v-else>
                    <a-avatar :src="companyAvatar"></a-avatar>
                    <span class="item-icons" v-if="loginType === 'qiye'">企</span>
                    <span class="item-icons" v-else-if="loginType === 'jigou'">金</span>
                    <span class="item-icons" v-else-if="loginType === 'zhengfu'">政</span>
                </div>
                <p v-if="orgName != '' && orgName != 'null' && orgName != null">{{orgName}}</p>
                <p v-else>尚未认证</p>
           </div>
           <div class="mb-right">
               <div class="main-box-nav">
                    <a-menu mode="horizontal">
                        <a-menu-item key="WareHouse"><router-link to="/PersonalLayout/WareHouse">数据分析</router-link></a-menu-item>
                        <a-sub-menu v-if="loginType === 'jigou' || loginType === 'qiye'">
                            <template #title>
                                <span class="submenu-title-wrapper">金融服务<DownOutlined/></span>
                            </template>
                            <a-menu-item-group>
                                <a-menu-item key="setting:1" v-if="loginType === 'qiye' && regType === 'E'">
                                    <router-link to="/PersonalLayout/EnterpriseCertification">企业认证</router-link>
                                </a-menu-item>
                                <a-menu-item key="setting:2" v-if="loginType === 'qiye' && regType === 'I'">
                                    <router-link to="/PersonalLayout/IndividualCertification">个体认证</router-link>
                                </a-menu-item>
                                <a-menu-item key="setting:3" v-if="loginType === 'qiye'">
                                    <router-link to="/PersonalLayout/PostDemand" v-if="isAuth === 'Y'">需求发布</router-link>
                                    <span @click="onWarn" v-else>需求发布</span>
                                </a-menu-item>
                                <a-menu-item key="setting:6" v-if="loginType === 'jigou'">
                                    <router-link to="/PersonalLayout/ReleaseProduct">产品发布</router-link>
                                </a-menu-item>
                                <a-menu-item key="setting:4">
                                    <router-link to="/PersonalLayout/ResourcePool" v-if="isAuth === 'Y' && loginType === 'qiye'">资源池</router-link>
                                    <router-link to="/PersonalLayout/ResourcePool" v-else-if="loginType === 'jigou'">资源池</router-link>
                                    <span @click="onWarn" v-else-if="loginType === 'qiye' && isAuth === 'N'">资源池</span>
                                </a-menu-item>
                                <a-menu-item key="setting:5" v-if="loginType === 'qiye'">
                                    <router-link to="/PersonalLayout/MyService" v-if="isAuth === 'Y'">我的服务</router-link>
                                    <span @click="onWarn" v-else>我的服务</span>
                                </a-menu-item>
                            </a-menu-item-group>
                        </a-sub-menu>
                        <a-menu-item key="ResourcePool" v-if="loginType === 'zhengfu'"><router-link to="/PersonalLayout/ResourcePool">金融服务</router-link></a-menu-item>
                        <a-menu-item key="Business" v-if="loginType === 'jigou'" class="dropdown"><router-link to="/PersonalLayout/Business">业务受理</router-link></a-menu-item>
                        <a-menu-item key="" v-if="loginType === 'zhengfu'"><router-link to="/InforManage/AddInfor">信息管理</router-link></a-menu-item>
                        <a-sub-menu v-if="loginType === 'qiye'">
                            <template #title>
                                <span class="submenu-title-wrapper">
                                综合分析
                                <DownOutlined/>
                                </span>
                            </template>
                            <a-menu-item-group>
                                <a-menu-item key="setting:8" v-if="loginType === 'qiye'">
                                    <router-link to="/PersonalLayout/EnterpriseDiagnosis" v-if="isAuth === 'Y' && isUploadImg === 'true'">企业诊断</router-link>
                                    <span @click="onWarn11" v-else-if="isAuth === 'Y' && isUploadImg === 'false'">企业诊断</span>
                                    <span @click="onWarn" v-else>企业诊断</span>
                                    
                                </a-menu-item>
                                <!-- <a-menu-item key="setting:9">
                                    <router-link to="/PersonalLayout/CreditReport">征信报告</router-link>
                                </a-menu-item> -->
                                <a-menu-item key="setting:10" v-if="loginType === 'qiye'">
                                    <router-link to="/PersonalLayout/InDepthAnalysisReport" v-if="isAuth === 'Y' && isUploadImg === 'true'">深度分析报告</router-link>
                                    <span @click="onWarn11" v-else-if="isAuth === 'Y' && isUploadImg === 'false'">深度分析报告</span>
                                    <span @click="onWarn" v-else>深度分析报告</span>
                                </a-menu-item>
                            </a-menu-item-group>
                        </a-sub-menu>

<!--                      信用中国月贷款统计-->
                      <a-sub-menu v-if="loginType === 'jigou'">
                        <template #title>
                          <span class="submenu-title-wrapper">贷款统计<DownOutlined/></span>
                        </template>
                        <a-menu-item-group>
                          <a-menu-item key="setting:1" v-if="loginType === 'jigou'">
                            <router-link to="/ReportForms/place">地方维度</router-link>
                          </a-menu-item>
                          <a-menu-item key="setting:2" v-if="loginType === 'jigou'">
                            <router-link to="/ReportForms/financialInstitution">金融机构维度</router-link>
                          </a-menu-item>
                          <a-menu-item key="setting:3" v-if="loginType === 'jigou'">
                            <router-link to="/ReportForms/platform">平台维度</router-link>
                          </a-menu-item>
                        </a-menu-item-group>
                      </a-sub-menu>


                        <a-menu-item  v-if="loginType === 'jigou'" key="WareHouse">
                            <a href="http://www.fanshichanrong.com:9608/white/cockpit">贷后监管</a>
                        </a-menu-item>

                        <a-menu-item key="Settings">
                            <a href="/PersonalLayout/Settings">个人设置</a>
                        </a-menu-item>
                        <a-menu-item key="notice">
                            <router-link to="/PersonalLayout/Message">系统通知</router-link>
                        </a-menu-item>
                        <a-menu-item key="exit" @click="outShow = true"><RightSquareOutlined style="margin-right:5px;" />退出登录</a-menu-item>
                    </a-menu>
               </div>
           </div>
        </div>
        <a-modal
            :visible="outShow"
            title="信息提示"
            @cancel="outShow = false"
            @ok="onLoginOut"
            ok-text="确定"
            cancel-text="取消"
        >
            <p>您确定要退出登录吗？</p>
        </a-modal>
    </div>
</template>

<script>
import {
    RightSquareOutlined,
    DownOutlined
} from '@ant-design/icons-vue'
// import companyAvatar from '@/assets/images/companyAvatar.jpg'
import companyAvatar from "@/assets/images/logob copy.png";
import { mapGetters } from 'vuex'
import { logout } from '@/api/login/login'
export default {
    components: {
        RightSquareOutlined,
        DownOutlined
    },
    computed: {
        ...mapGetters(['loginType', 'isAuth', 'orgName', 'regType','userName','isUploadImg'])
    },
    data() {
        return {
            companyAvatar: companyAvatar,
            isActive: false,
            outShow: false
        }
    },
    methods: {
        addlink() {
            this.isActive = true
        },
        removelink(){
            this.isActive = false
        },
        onLoginOut () {
            this.outShow = false
            logout({tm:new Date().getTime(),USERNAME:this.userName}).then(res=>{

            })
            this.$store.commit('loginOut')
            this.$router.push({ path: '/' })
        },
        onWarn () {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
        },
        onWarn11(){
            this.$message.info('请在企业认证中先完成企业信息上传')
        }
    },
    mounted(){
    }
}
</script>
<style  lang="less" scoped>
.ant-badge::v-deep(.ant-badge-count){
    right: -12px;
}
.ant-dropdown-menu-item{
    padding: 8px 12px;
}
.ant-dropdown-menu{
    text-align: center;
}
.main-box-nav ::v-deep(.ant-menu){
    width: 100%;
    display: flex;
    border-bottom: 0;
    font-size: 16px;
    justify-content: space-between;
}
::v-deep(.ant-menu-item-group-title){
    display: none;
}
::v-deep(.ant-menu-item){
    text-align: center;
    padding: 0 15px;
}

</style>

<style lang="less" scoped>
.header-box{
   .main-box{
        width: @main-width-base;
        background-color: @color-ff;
        margin:20px auto;
        padding: 15px;
        height: 90px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px #929292;
        display: flex;
        justify-content: space-between;
        .mb-left{
            width: 400px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .loginc-l-box{
                position: relative;
                width: 60px;
                .ant-avatar{
                    width: 60px;
                    height: 60px;
                    border: @border-base;
                    border-radius: 100%;
                }
                .item-icons{
                    font-size: 14px;
                    color: #fff;
                    background-color: #02c8c9;
                    display: block;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    border-radius: 100%;
                    text-align: center;
                    position: absolute;
                    right:-10px;
                    bottom: 10px;
                }
            }
            p{
                margin-bottom: 0;
                width: 320px;
                font-size: @font-xl;
                color: @color-base;
            }
        }
        .mb-right{
            width: 990px;
            padding-left: 20px;
            border-left: @border-base;

        }
   }
}
.main-box-nav{
    height: 60px;
    // a{
    //     padding:10px 20px;
    //     text-align: center;
    //     color: #999999;
    //     font-size: @font-lg;
    // }
    // a:hover{
    //     color: #3352c5;
    //     border-bottom: 2px solid #3352c5;

    // }
    // .active{
    //     color: #3352c5;
    //     border-bottom: 2px solid #3352c5;
    // }
    // .router-link-active{
    //     color: #3352c5;
    //     border-bottom: 2px solid #3352c5;
    // }
    // .system{
    //     padding-left: 20px;
    //     border-left:@border-base;
    // }
}

</style>
