<template>
    <div>
      <PersonalHeader></PersonalHeader>
        <router-view />
    </div>
</template>

<script>
  import PersonalHeader from '../components/PersonalHeader'
  export default {
    name: 'PersonalLayout',
    components: {
      PersonalHeader
    },
    data(){
        return {

        }
    },
    methods:{}
  }
</script>

<style scoped>

</style>
